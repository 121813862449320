import { throttle, toDateString, unsafeUUID } from "@pentacode/core/src/util";
import { PentacodeAPIModels as Models, PentacodeAPIModels } from "@pentacode/core/src/rest/api";
import { PentacodeAPIClient } from "@pentacode/core/src/rest/client";
import { Storage } from "@pentacode/core/src/storage";
import { alert } from "@pentacode/app/src/elements/alert-dialog";
import { APIError } from "@pentacode/openapi";
import { hour, minute, getAvailableActions } from "@pentacode/core/src/time";
import { Exclude, Type } from "@pentacode/core/src/encoding";
import { TimeTrackerLog } from "@pentacode/core";
import { State } from "@pentacode/core/src/app";
import { html } from "lit";

export type TimeLogConnection = {
    deviceId: string;
    companyId: number;
    authToken: string;
};

class TimeLogState {
    constructor(init: Partial<TimeLogState> = {}) {
        Object.assign(this, init);
    }

    connection: TimeLogConnection | null = null;
    company: PentacodeAPIModels["Company"] | null = null;
    options: Models["TimeLogOption"][] = [];
    eventCue: PentacodeAPIModels["CreateTimeLogEventParams"][] = [];
    device: Models["TimeLogDevice"] | null = null;

    @Type(() => Date)
    lastSync?: Date;

    @Exclude()
    offline?: boolean;

    @Exclude()
    synching = false;
}

export type PerformActionParams = Pick<
    PentacodeAPIModels["CreateTimeLogEventParams"],
    "employeeId" | "timeEntryId" | "action" | "image" | "positionId" | "comment" | "location" | "meals"
>;

export function getTimeWindow(opt: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
    if (!opt.timeEntry.startPlanned && !opt.timeEntry.startFinal) {
        return [new Date(0), new Date(0)];
    }
    const start = new Date(opt.timeEntry.startFinal || opt.timeEntry.startPlanned!);

    return [
        new Date(start.getTime() - opt.settings.allowEarlyStart * hour),
        new Date(start.getTime() + opt.settings.lockAfter * hour),
    ];
}

export function isWithinTimeWindow(opt: PentacodeAPIModels["TimeLogOption"]["scheduled"][number], startBuffer = 0) {
    const [start, end] = getTimeWindow(opt);
    const now = Date.now();
    return now >= start.getTime() - startBuffer * hour && now <= end.getTime();
}

export function getCurrentShiftsByStatus(option: PentacodeAPIModels["TimeLogOption"]) {
    option.scheduled.forEach(
        (option) => (option.availableActions = getAvailableActions(option.timeEntry, option.settings))
    );

    const ready = option.scheduled.find(
        (s) => s.timeEntry.status === "scheduled" && s.availableActions.includes("startShift")
    );

    const upcoming = option.scheduled.find(
        (s) =>
            s.timeEntry.status === "scheduled" && !s.availableActions.includes("startShift") && isWithinTimeWindow(s, 3)
    );

    // 1. Check for paused shifts
    const paused = option.scheduled.find((s) => s.timeEntry.status === "paused" && isWithinTimeWindow(s));

    // 2. Check for active shifts
    const ongoing = option.scheduled.find((s) => s.timeEntry.status === "ongoing" && isWithinTimeWindow(s));

    // 3. Check for completed shifts
    const completed = option.scheduled.find((s) => s.timeEntry.status === "completed" && isWithinTimeWindow(s));

    return { ready, upcoming, paused, ongoing, completed };
}

export class TimeLogger {
    state: TimeLogState = new TimeLogState();

    private _resolveLoad: (value?: unknown) => void;

    loaded = new Promise((resolve) => (this._resolveLoad = resolve));

    private _client: PentacodeAPIClient;
    private _storage: Storage;

    private _pollTimeout: number;

    private _subscribers: Array<(state: TimeLogState) => void> = [];

    constructor(client: PentacodeAPIClient, storage: Storage) {
        this._client = client;
        this._storage = storage;
    }

    subscribe(fn: (state: TimeLogState) => void) {
        if (this._subscribers.indexOf(fn) === -1) {
            this._subscribers.push(fn);
        }
    }

    unsubscribe(fn: (state: TimeLogState) => void) {
        const index = this._subscribers.indexOf(fn);
        if (index === -1) {
            this._subscribers.splice(index, 1);
        }
    }

    publish() {
        for (const fn of this._subscribers) {
            fn(this.state);
        }
    }

    async connect(code: string) {
        const connection = await this._connect({ code });
        this._client.authToken = connection.authToken;
        await this._setState({ connection });
        await this.synchronize();
    }

    async disconnect() {
        this._client.authToken = undefined;
        await this._setState(new TimeLogState());
    }

    async init() {
        await this._loadState();

        if (this.state.connection) {
            await this._connect(this.state.connection);
            this._client.authToken = this.state.connection?.authToken;
            this.poll();
        }

        this._resolveLoad();
    }

    private async _loadState() {
        try {
            this.state = await this._storage.get(TimeLogState, "ptc-time-log-state");
        } catch (e) {}
        void this._cleanupOldTimeLoggerState();
    }

    private async _setState(vals: Partial<TimeLogState>) {
        Object.assign(this.state, vals);
        await this._saveState();
        this.publish();
    }

    private async _saveState() {
        await this._storage.set("ptc-time-log-state", this.state);
    }

    private async _cleanupOldTimeLoggerState() {
        try {
            const oldState = await this._storage.get(State, "state");
            if (oldState) {
                if (oldState.session) {
                    await alert(
                        html`<div class="vertical layout">
                            <div class="text">Die alte Stempeluhr wurde zum 27.11.2024 abgeschaltet.</div>

                            <div class="text">
                                Anweisungen, wie sie die neue Stempeluhr einrichten finden sie
                                <a
                                    href="https://pentacode.app/hilfe/handbuch/zeiterfassung/#stempeluhr-einrichten"
                                    target="_blank"
                                    >im Handbuch</a
                                >.
                            </div>
                        </div>`,
                        {
                            type: "info",
                            title: "Die Alte Stempeluhr wurde abgeschaltet",
                            preventDismiss: true,
                        }
                    );
                }
                await this._storage.delete(TimeTrackerLog, "time_log").catch(() => {});
                await this._storage.delete(State, "state").catch(() => {});
            }
        } catch (e) {}
    }

    private async _handleAPIError(e: APIError) {
        console.error(e);
        switch (e.status) {
            case 0:
                await this._setState({ offline: true });
                break;
            case 401:
                await alert("Ihre Sitzung ist Abgelaufen. Bitte verbinden Sie das Gerät erneut!", {
                    type: "warning",
                    title: "Sitzung Abgelaufen",
                });
                await this._disconnect();
                break;
            default:
                await alert(
                    e.message ||
                        "Es ist ein unerwarteter Fehler aufgetreten. Das Pentacode Team wurde informiert und wird das Problem schnellstmöglich beheben. Bitte versuchen Sie es später noch einmal!",
                    {
                        type: "warning",
                        title: "Unerwarteter Fehler",
                    }
                );
        }
    }

    poll = throttle(async (interval: number = minute * 5) => {
        if (!this._client.authToken) {
            return;
        }

        clearTimeout(this._pollTimeout);
        await this.synchronize();

        this._pollTimeout = window.setTimeout(() => this.poll(interval), interval);
    }, 5000);

    private async _disconnect() {
        await this._setState(new TimeLogState());
    }

    get options() {
        return this.state.options;
    }

    get employees() {
        return this.options.map((option) => option.employee);
    }

    get upcomingShifts() {
        const options = this.options;
        return options
            .flatMap(({ employee, scheduled }) =>
                scheduled
                    .filter((opt) => opt.timeEntry.status === "scheduled" && isWithinTimeWindow(opt))
                    .map(({ timeEntry }) => ({ employee, timeEntry }))
            )
            .sort(
                (a, b) => new Date(a.timeEntry.startPlanned!).getTime() - new Date(b.timeEntry.startPlanned!).getTime()
            );
    }

    get activeShifts() {
        const options = this.options;
        return options
            .flatMap(({ employee, scheduled }) =>
                scheduled
                    .filter((opt) => ["paused", "ongoing"].includes(opt.timeEntry.status) && isWithinTimeWindow(opt))
                    .map(({ timeEntry }) => ({ employee, timeEntry }))
            )
            .sort((a, b) => new Date(a.timeEntry.startFinal!).getTime() - new Date(b.timeEntry.startFinal!).getTime());
    }

    private async _connect(params: { code: string } | TimeLogConnection) {
        if ("code" in params) {
            const connection = await this._client.api.connectTimeLogDevice({}, params);
            await this._setState({ connection });
        } else {
            await this._setState({ connection: params });
        }

        this._client.authToken = this.state.connection!.authToken;
        return this.state.connection!;
    }

    getOption(opts: { staffNumber: string } | { pin: string }): Models["TimeLogOption"] | null {
        const option =
            "pin" in opts
                ? this.state.options.find((item) => item.employee.timeLogPin === opts.pin)
                : this.state.options.find((item) => item.employee.staffNumber === opts.staffNumber);

        if (!option) {
            return null;
        }

        return option;
    }

    async performAction({
        employeeId,
        action,
        positionId,
        timeEntryId,
        comment,
        image,
        location,
        meals,
    }: PerformActionParams) {
        if (!this.state.connection) {
            return;
        }

        if (!employeeId) return;

        const time = new Date().toISOString();
        let event: PentacodeAPIModels["CreateTimeLogEventParams"];
        const option = this.state.options.find((o) => o.employee.id === employeeId);
        if (!option) {
            return;
        }

        if (action === "startShift" && !timeEntryId) {
            const unscheduledItem = option.unscheduled.find((u) => u.position.id === positionId);
            if (!unscheduledItem) {
                return;
            }

            const { settings, position } = unscheduledItem;
            // Generate temporary ID
            const timeEntryId = `temp_${unsafeUUID()}`;
            event = {
                action,
                positionId,
                employeeId,
                timeEntryId,
                deviceId: this.state.connection.deviceId,
                time,
                comment,
                image,
                location,
                meals,
            };
            option.scheduled.push({
                settings,
                timeEntry: {
                    type: "work",
                    id: timeEntryId,
                    startPlanned: null,
                    endPlanned: null,
                    startLogged: time,
                    endLogged: null,
                    startFinal: null,
                    endFinal: null,
                    currentBreakStart: null,
                    date: toDateString(new Date()),
                    status: "ongoing",
                    position,
                    employeeId: employeeId || null,
                },
                availableActions: ["startBreak", "endShift"],
            });
        } else {
            const scheduledItem = option.scheduled.find((s) => s.timeEntry.id === timeEntryId);

            if (!scheduledItem) {
                return;
            }

            const { timeEntry } = scheduledItem;

            event = {
                deviceId: this.state.connection.deviceId,
                action,
                timeEntryId: timeEntry.id,
                employeeId,
                time,
                comment,
                image,
                location,
                meals,
            };

            switch (action) {
                case "startShift":
                case "endBreak":
                    timeEntry.status = "ongoing";
                    timeEntry.currentBreakStart = null;
                    scheduledItem.availableActions = ["startBreak", "endShift"];
                    break;
                case "startBreak":
                    timeEntry.status = "paused";
                    timeEntry.currentBreakStart = time;
                    scheduledItem.availableActions = ["endBreak", "endShift"];
                    break;
                case "endShift":
                    timeEntry.status = "completed";
                    scheduledItem.availableActions = [];
                    break;
            }
        }

        this.state.eventCue.push(event);
        await this._saveState();
        await this.synchronize();
    }

    async synchronize() {
        if (this.state.synching) {
            return;
        }

        await this._setState({ synching: true });

        try {
            await this.clearActionCue();
            await this._fetchCompany();
            await this._fetchDevice();
            await this._fetchOptions();
            await this._setState({ offline: false });
        } catch (e) {
            await this._handleAPIError(e);
        }
        await this._setState({ lastSync: new Date(), synching: false });
    }

    private async _fetchCompany() {
        const company = await this._client.api.getCompany({});
        await this._setState({ company });
    }

    private async _fetchDevice() {
        if (!this.state.connection) {
            return;
        }
        const device = await this._client.api.getTimeLogDevice({ id: this.state.connection.deviceId });
        await this._setState({ device });
    }

    private async _fetchOptions() {
        if (!this.state.connection) {
            throw "Not connected!";
        }

        const timeFrom = new Date();
        timeFrom.setTime(timeFrom.getTime() - 24 * 60 * 60 * 1000);

        const timeTo = new Date(timeFrom);
        timeTo.setDate(timeTo.getDate() + 3);

        const options: PentacodeAPIModels["TimeLogOption"][] = [];
        let res:
            | { limit: number; offset: number; total: number; data: PentacodeAPIModels["TimeLogOption"][] }
            | undefined = undefined;

        while (!res || res.total > res.offset + res.data.length) {
            const limit: number = res?.limit || 1000;
            const offset: number = res ? res.offset + res.data.length : 0;
            res = await this._client.api.getTimeLogOptions({
                deviceId: this.state.connection.deviceId,
                from: timeFrom.toISOString(),
                to: timeTo.toISOString(),
                offset,
                limit,
            });

            options.push(...res.data);
        }

        await this._setState({ options });
    }

    async clearActionCue() {
        while (this.state.eventCue.length) {
            const event = this.state.eventCue[0];

            let replaceId: string | undefined = undefined;

            if (event.action === "startShift" && event.timeEntryId?.startsWith("temp_")) {
                replaceId = event.timeEntryId;
                delete event.timeEntryId;
            }

            try {
                const res = await this._client.api.createTimeLogEvent(
                    {},
                    event as PentacodeAPIModels["TimeLogEvent"] & { deviceId: string }
                );

                if (replaceId) {
                    this.state.eventCue.forEach((event) => {
                        if (event.timeEntryId === replaceId) {
                            event.timeEntryId = res.timeEntryId;
                        }
                    });
                }

                this.state.eventCue.shift();
            } catch (e) {
                if (replaceId) {
                    event.timeEntryId = replaceId;
                }
                break;
            }
        }
    }
}
